import React from 'react';
import { Store } from 'react-notifications-component';
import ToastNotification from '../shared/components/ToastNotification';
import { ToastNotificationType } from '../shared/types/types';

export const toastSuccess = (title: string, message: string = '', duration?: number) => {
  toastCore(title, message, 'success', duration);
};

export const toastInfo = (title: string, message: string = '', duration?: number) => {
  toastCore(title, message, 'info', duration);
};

export const toastDanger = (title: string, message: string = '', duration?: number) => {
  toastCore(title, message, 'danger', duration);
};

/**
 * Duration: (ms)
 */
export const toastWarning = (title: string, message: string = '', duration?: number) => {
  toastCore(title, message, 'warning', duration);
};

const toastCore = (
  title: string, message: string,
  type: ToastNotificationType, duration: number = 4000,
) => {
  const content = () => (<ToastNotification type={type} title={title} message={message} />);
  Store.addNotification({
    content,
    title,
    message,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration,
    },
  });
};

const H2 = () => (<p>Success</p>);

export const toastCore2 = () => {
  Store.addNotification({
    content: H2,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
    },
  });
};
