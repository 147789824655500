const ROUTES = {
  /* ********* Home ********* */
  homePath: '/',
  allNotes: '/?page=all_notes',
  scratchpad: '/scratchpad',
  createNewWorkspace: '/workspace/create',
  inviteMembersToWorkspace: '/workspace/invite',

  /* ********* Onboarding ********* */
  onboardingPath: '/onboarding',
  onboardingPersonalPath: '/onboarding/personal',
  onboardingFirstMeetingPath: '/onboarding/firstmeeting',
  onboardingWorkspacePath: '/onboarding/workspace',
  onboardingWelcomePath: '/onboarding/welcome',

  /* ********* Signin ********* */
  signinPath: '/signin',

  /* ********* Logout ********* */
  logoutPath: '/logout',

  /* ********* Dashboard ********* */
  dashboardPath: '/dashboard',

  /* ********* Meeting ********* */
  meetingPath: '/meeting',
  meetingWithMeetingIdPath: '/meeting/:meetingId',

  /* ********* Previous Meeting ********* */
  previousMeetingPath: '/meeting/:meetingId?/previousMeeting/:previousMeetingId?',

  /* ********* Invite ********* */
  invites: '/invites',

  /* ********* Tutorial Video ********* */
  tutorialVideoPath: '/tutorialVideo',

  /* ********* Notion Redirect Page ********* */
  notionRedirectPath: '/notion',

  /* ********* Notion Redirect Page ********* */
  trelloRedirectPath: '/trello',

  /* ********* Slack Redirect Page ********* */
  slackRedirectPath: '/integrations/slack',

  /* ********* Desktop Notification Redirect Page ********* */
  desktopNotificationRedirectPath: '/notification',

  /* ********* Google Calendar ********* */
  GOOGLE_MEET_ROUTE: '/google-meet/:googleMeetId',
  GOOGLE_CALENDAR_ROUTE: '/google-calendar/:eventId/:calendarId',

  /* ********* Testing ********* */
  testingPath: '/testing',
  ivanTestingPath: '/ivan',
  haraldTestingPath: '/harald',
  shaokunTestingPath: '/shaokun',
  matthewTestingPath: '/matthew',
  asishTestingPath: '/asish',
  guestTestingPath: '/guest',
  manage_billing: '/manage',
  manage_workspaces: '/workspace/manage',

  successfullPayment: '/successfull_payment',
  cancelPayment: '/cancel_payment',
};

export default ROUTES;
