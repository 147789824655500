/* eslint-disable max-len */
import {
  PublicUserDataV2, User, WorkspaceDb, WorkspacePermission, WorkspacePermissionExpanded,
} from '../../shared/types/types';
import { StringUtils } from '../../utils/strings';
import WorkspaceAPI from './WorkspaceAPI';
import WorkspaceDbClass from './WorkspaceDbClass';
import WorkspaceMember from './WorkspaceMember';
import WorkspacesUtils from './WorkspacesUtils';

/**
 * Consider rather implementing WorkspaceDb in stead of using composition.
 */
class WorkspaceData extends WorkspaceDbClass {
  workspaceId: string;

  constructor(workspaceId: string, value: WorkspaceDb) {
    super(value);
    this.workspaceId = workspaceId;
  }

  get name(): string {
    return this.data.name;
  }

  /**
   * Evaluate number of members from the members array.
   * If a user is an admin, they will both be in the admin and members array
   */
  get numberOfMembers(): number {
    return this.permissions.members.length;
  }

  /**
   * Unique member emails
   */
  get memberEmails(): string[] {
    const emails = [
      ...this.permissions.members,
      ...this.permissions.admins,
      ...this.permissions.managers,
      ...this.permissions.editors,
      ...this.permissions.invites];
    return StringUtils.uniqueStrings(emails);
  }

  /**
   * To check if an email has editor permissions for a workspace
   */
  isEditor(email: string): boolean {
    if (this.permissions.editors.includes(email)) return true;
    if (this.permissions.managers.includes(email)) return true;
    if (this.permissions.admins.includes(email)) return true;
    return false;
  }

  getPermission(email: string): WorkspacePermissionExpanded {
    if (this.permissions.admins.includes(email)) return 'admin';
    if (this.permissions.managers.includes(email)) return 'manager';
    if (this.permissions.editors.includes(email)) return 'editor';
    if (this.permissions.members.includes(email)) return 'member';
    if (this.permissions.invites.includes(email)) return 'invited';
    return null;
  }

  isMemberOrInvited(email: string): boolean {
    if (this.getPermission(email)) return true;
    return false;
  }

  async getMembers(
    // eslint-disable-next-line no-unused-vars
    fetchUsersByEmails: (emails: string[]) => Promise<PublicUserDataV2[]>,
  ): Promise<WorkspaceMember[]> {
    const publicUsers = await fetchUsersByEmails(this.memberEmails);
    const newMembers: WorkspaceMember[] = [];
    newMembers.push(...WorkspaceData.createWorkspaceMember(this.permissions.admins, 'admin', publicUsers, []));
    newMembers.push(...WorkspaceData.createWorkspaceMember(this.permissions.managers, 'manager', publicUsers, newMembers));
    newMembers.push(...WorkspaceData.createWorkspaceMember(this.permissions.editors, 'editor', publicUsers, newMembers));
    newMembers.push(...WorkspaceData.createWorkspaceMember(this.permissions.members, 'member', publicUsers, newMembers));
    newMembers.push(...WorkspaceData.createWorkspaceMember(this.permissions.invites, 'invited', publicUsers, newMembers));
    return newMembers;
  }

  private get subscriptionStatus(): string {
    return this.billing.subscription.status;
  }

  isValid(): boolean {
    if (this.data.name === undefined) return false;
    if (this.workspaceId === '') return false;
    return true;
  }

  /**
   * Checks whether the workspace is on premium plan.
   * First version will not check that the specific user in the workspace is on premium,
   * but just that the workspace is on premium.
   * Later I need to also check that the user has a permission
   * set to editor or above to qualify for premium.
   */
  isOnPremium(): boolean {
    return this.subscriptionStatus === 'active'
      || this.subscriptionStatus === 'incomplete'
      || this.subscriptionStatus === 'trialing';
  }

  // TODO: Not finished
  async getWorkspaceMembers(): Promise<WorkspaceMember[]> {
    const members: WorkspaceMember[] = [];
    this.permissions.admins.forEach((admin) => {
      const member = WorkspaceMember.createDummy(
        admin, admin, '', admin, 'admin',
      );
      members.push(member);
    });
    return members;
  }

  /**
   * Get workspace by workspaceId from database
   */
  static async get(workspaceId: string, userId: string, user: User) {
    const result = await WorkspaceAPI.getWorkspaceByWorkspaceId(workspaceId, userId, user);
    return result.value;
  }

  static createDummy(name: string = '', numberOfMembers: number = 1) {
    const members = Array.from({ length: numberOfMembers }, (_, i) => `user${i + 1}`);
    const data = { data: { name }, permissions: { members } };
    const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(data);
    return new WorkspaceData('', workspaceDb);
  }

  static createWorkspaceMember(
    newEmails: string[],
    newPermission: WorkspacePermission,
    candidates: PublicUserDataV2[],
    existingMembers: WorkspaceMember[],
  ): WorkspaceMember[] {
    const newMembers: (WorkspaceMember | null)[] = newEmails.map((newEmail) => {
      if (existingMembers.find((existingMember) => existingMember.email === newEmail)) return null;
      const publicUser = candidates.find((user) => user.data.email === newEmail);
      if (!publicUser) return null;
      return new WorkspaceMember(publicUser, newPermission);
    });
    return newMembers.filter((newMember) => newMember !== null) as WorkspaceMember[];
  }
}

export default WorkspaceData;
