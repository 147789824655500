import BillingUserData from '../../external/Stripe/BillingUserData';
import {
  FriendListV2, GoogleOfflineTokens, IntegrationData, OnboardingData,
  PublicUserData, PublicUserDataV2, ResolvedState, SDateT, User,
  UserInviteesData, UserSettings, UserWorkspaceData,
} from '../types/types';

class UserClass implements User {
  resolvedState: ResolvedState;

  userId: string;

  friendListV2: FriendListV2;

  publicUserData: PublicUserDataV2;

  billing: BillingUserData;

  workspaces: UserWorkspaceData;

  data: {
    name: string;
    email: string;
    firstName: string,
    lastName: string,
    photoUrl: string
    newFeaturesViewed: string[],
    hasDismissedDesktopNotification: boolean,
    hasOnboarded: boolean,
    onboarding: OnboardingData,
    receivedWelcomeEmail: boolean
  }

  meta: {
    admin: boolean;
    analytics: {
      hasUsedScratchpad: boolean;
    },
    /** Private Id, only known to the user. Used by Scratchpad to create a private note */
    privateId: string,
  };

  settings: UserSettings;

  googleTokens: GoogleOfflineTokens

  date: {
    created: SDateT;
    updated: SDateT;
  };

  integrations: IntegrationData;

  invitees: UserInviteesData

  friendList: PublicUserData[];

  permissions: {
    google: {
      calendars: string[]
    },
    tags: string[]
  }

  constructor(
    user: User,
  ) {
    this.billing = user.billing;
    this.data = user.data;
    this.date = user.date;
    this.friendList = user.friendList;
    this.friendListV2 = user.friendListV2;
    this.googleTokens = user.googleTokens;
    this.integrations = user.integrations;
    this.invitees = user.invitees;
    this.meta = user.meta;
    this.permissions = user.permissions;
    this.publicUserData = user.publicUserData;
    this.resolvedState = user.resolvedState;
    this.settings = user.settings;
    this.userId = user.userId;
    this.workspaces = user.workspaces;
  }
}

export default UserClass;
