import {
  collection, onSnapshot, query,
  addDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc, getDoc, getDocs, updateDoc, where,
} from 'firebase/firestore';
import SmartReturnData from '../../shared/classes/SmartReturnData';
import {
  ResolvedState, User, WorkspaceDb, WorkspacePermissionExpanded,
} from '../../shared/types/types';
import SentryAPI from '../../utils/analytics/SentryAPI';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';
import { COLLECTIONS, WORKSPACE_PATH } from '../FirebaseConstants';
import WorkspaceData from './WorkspaceData';
import WorkspacesUtils from './WorkspacesUtils';
import InviteClass from '../../shared/classes/Invite/InviteClass';
import UserWorkspacesAPI from '../User/UserWorkspacesAPI';
import CloudFunctions from '../CloudFunctions';

class WorkspaceCoreAPI {
  protected static async coreGetWorkspaceByWorkspaceId(
    workspaceId: string, user: User,
  ): Promise<SmartReturnData<WorkspaceData>> {
    if (!workspaceId || workspaceId === 'pending') {
      UserWorkspacesAPI.assignWorkspaceFromExistingWorkspacesOrCreateNew(user);
      return new SmartReturnData(WorkspaceData.createDummy(), 'rejected', 'WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: workspaceId is null');
    }
    // const result = await firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .doc(workspaceId)
    //   .get()
    const result = await getDoc(doc(firestore, COLLECTIONS.WORKSPACES, workspaceId))
      .then((document) => {
        if (!document.exists()) {
          console.log("WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: doc doesn't exist", workspaceId);
          return new SmartReturnData(WorkspaceData.createDummy(), 'rejected', 'WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId: doc does not exist');
        }

        const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(document.data());
        const workspaceData = new WorkspaceData(document.id, workspaceDb);
        return new SmartReturnData(workspaceData, RESOLVED);
      })
      .catch((error) => {
        UserWorkspacesAPI.assignWorkspaceFromExistingWorkspacesOrCreateNew(user);
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId', error, workspaceId);
        return new SmartReturnData(WorkspaceData.createDummy(), 'rejected', 'WorkspaceCoreAPI.coreGetWorkspaceByWorkspaceId error');
      });
    return result;
  }

  protected static coreGetWorkspaces = async (email: string) => {
    // const result = await firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .where('permissions.members', 'array-contains', email)
    //   .get()
    const q = query(collection(firestore, COLLECTIONS.WORKSPACES),
      where('permissions.members', 'array-contains', email));
    const result = await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          return [];
        }

        const workspaces: WorkspaceData[] = querySnapshot.docs
          .map((document) => {
            const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(document.data());
            return new WorkspaceData(document.id, workspaceDb);
          })
          .sort(WorkspacesUtils.sortByCreatedDate);
        return workspaces;
      })
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreGetWorkspaces', error, email);
        return [] as WorkspaceData[];
      });
    console.log('workspaces in coreGetWorkspaces', result);
    return result;
  }

  protected static coreListenToMyWorkspaces = (
    // eslint-disable-next-line no-unused-vars
    email: string, callback: (workspaces: WorkspaceData[]) => void,
    // eslint-disable-next-line no-unused-vars
    setIsLoading: (isLoading: boolean) => void,
  ) => {
    setIsLoading(true);
    // const unsubscribe = firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .where('permissions.members', 'array-contains', email)
    //   .onSnapshot((querySnapshot) => {
    const unsubscribe = onSnapshot(query(collection(firestore, COLLECTIONS.WORKSPACES),
      where('permissions.members', 'array-contains', email)), (querySnapshot) => {
      if (querySnapshot.empty) {
        callback([]);
        return;
      }

      const workspaces: WorkspaceData[] = querySnapshot.docs
        .map((document) => {
          const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(document.data());
          return new WorkspaceData(document.id, workspaceDb);
        })
        .sort(WorkspacesUtils.sortByCreatedDate);

      callback(workspaces);
      console.log('%cS%c Workspaces', 'color:cyan; font-size: 8px', '', workspaces);
      setIsLoading(false);
    }, (error) => {
      SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.listenToMyWorkspaces', error, email);
      callback([] as WorkspaceData[]);
      setIsLoading(false);
    });

    return unsubscribe;
  }

  protected static coreListenToWorkspace = (
    workspaceId: string,
    // eslint-disable-next-line no-unused-vars
    callback: (workspace: WorkspaceData) => void, setIsLoading: (isLoading: boolean) => void,
    user: User,
  ) => {
    setIsLoading(true);
    // const unsubscribe = firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .doc(workspaceId)
    //   .onSnapshot((querySnapshot) => {
    const unsubscribe = onSnapshot(doc(firestore, COLLECTIONS.WORKSPACES, workspaceId),
      (querySnapshot) => {
        if (!querySnapshot.exists()) {
        // callback(new WorkspaceData(querySnapshot.id, querySnapshot.data() as WorkspaceDb));
          return;
        }

        const workspaceDb = WorkspacesUtils.mapDbToWorkspaceDb(querySnapshot.data());
        const workspace = new WorkspaceData(querySnapshot.id, workspaceDb);
        callback(workspace);
        setIsLoading(false);
      }, (error) => {
        UserWorkspacesAPI.assignWorkspaceFromExistingWorkspacesOrCreateNew(user);
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreListenToWorkspace', error, workspaceId);
        // callback();
        setIsLoading(false);
      });

    return unsubscribe;
  }

  /**
   * @returns workspaceId - the id of the created workspace
   */
  protected static coreCreateWorkspace = async (workspace: WorkspaceDb) => {
    // const result = await firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .add(workspace)
    const result = await addDoc(collection(firestore, COLLECTIONS.WORKSPACES), workspace)
      .then((docRef) => docRef.id as string)
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreCreateWorkspace', error, workspace);
        return '';
      });

    console.log('result in coreCreateWorkspace', result);
    return result;
  }

  protected static coreDeleteWorkspace = async (workspaceId: string) => {
    // const result = firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .doc(workspaceId)
    //   .delete()
    const result = await deleteDoc(doc(firestore, COLLECTIONS.WORKSPACES, workspaceId))
      .then(() => 'resolved' as ResolvedState)
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreDeleteWorkspace', error, workspaceId);
        return REJECTED;
      });

    return result;
  }

  protected static coreInviteMemberToWorkspace = async (workspaceId: string, email: string) => {
    const updates = {
      [WORKSPACE_PATH.permissions.invites]: arrayUnion(email),
    };
    // const result = firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .doc(workspaceId)
    //   .update(updates)
    const result = updateDoc(doc(firestore, COLLECTIONS.WORKSPACES, workspaceId), updates)
      .then(() => SmartReturnData.resolved())
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreInviteMemberToWorkspace', error, workspaceId);
        return SmartReturnData.rejected(error);
      });

    return result;
  }

  // TODO: If on premium, need to upgrade to add seats to subscription
  protected static coreChangePermissionsOfMember = (
    workspaceId: string, email: string, newPermission: WorkspacePermissionExpanded,
  ) => {
    console.log('coreChangePermissionsOfMember', { workspaceId, email, newPermission });
    let updates = {};
    if (newPermission === null) {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.invites]: arrayRemove(email),
      };
    }
    if (newPermission === 'admin') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: arrayUnion(email),
        [WORKSPACE_PATH.permissions.managers]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: arrayUnion(email),
      };
    }
    if (newPermission === 'manager') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: arrayUnion(email),
        [WORKSPACE_PATH.permissions.editors]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: arrayUnion(email),
      };
    }
    if (newPermission === 'editor') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: arrayUnion(email),
        [WORKSPACE_PATH.permissions.members]: arrayUnion(email),
      };
    }
    if (newPermission === 'member') {
      updates = {
        [WORKSPACE_PATH.permissions.admins]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.managers]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.editors]: arrayRemove(email),
        [WORKSPACE_PATH.permissions.members]: arrayUnion(email),
      };
    }

    // const result = firestore()
    //   .collection(COLLECTIONS.WORKSPACES)
    //   .doc(workspaceId)
    //   .update(updates)
    const result = updateDoc(doc(firestore, COLLECTIONS.WORKSPACES, workspaceId), updates)
      .then(() => SmartReturnData.resolved())
      .catch((error) => {
        SentryAPI.captureExceptionAndConsoleError('WorkspaceCoreAPI.coreChangePermissionsOfMember', error, workspaceId);
        return SmartReturnData.rejected(error);
      });

    return result;
  }

  /**
   * We don't need to send email since inside the CF we fetch the email from the
   * authenticated user
   */
  protected static coreGetInvitesByEmail = async (): Promise<InviteClass[]> => CloudFunctions()
    .getInvites()
    // eslint-disable-next-line arrow-body-style
    .then((result: any) => {
      if (!result.data) return [];
      // console.log('result in coreGetInvitesByEmail', result);
      return result.data.map((invite: any) => new InviteClass(invite));
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreGetInvitesByEmail', error);
      throw new Error(error);
    })

  protected static coreAcceptInvite = async (workspaceId: string) => CloudFunctions()
    .acceptWorkspaceInvite({ workspaceId })
    .then((result) => {
      console.log('result in coreAcceptInvite', result);
      return result;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreAcceptInvite', error);
      throw new Error(error);
    })

  protected static coreDeclineInvite = async (workspaceId: string) => CloudFunctions()
    .declineWorkspaceInvite({ workspaceId })
    .then((result) => {
      console.log('result in coreDeclineInvite', result);
      return result;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('coreDeclineInvite', error);
      throw new Error(error);
    })

  protected static considerSeatsForWorkspaceSubscription = async (
    workspaceId: string,
  ) => CloudFunctions().considerSeatsForWorkspaceSubscription({ workspaceId })
    .then((result) => {
      console.log('result in considerSeatsForWorkspaceSubscription', result);
      return result;
    })
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('considerSeatsForWorkspaceSubscription', error);
    })

  protected static removeYourselfFromWorkspace = async (
    workspaceId: string,
  ) => CloudFunctions().removeYourselfFromWorkspace({ workspaceId })
    .then((result) => result)
    .catch((error) => {
      SentryAPI.captureExceptionAndConsoleError('removeYourselfFromWorkspace', error);
      throw new Error(error);
    })
}

export default WorkspaceCoreAPI;
