import { Dispatch, SetStateAction } from 'react';
import { captureException } from '@sentry/react';
import { doc, runTransaction, onSnapshot } from 'firebase/firestore';
import dbGetUserWithFriendList from '../../external/FriendListV2/dbFriendListV2Api';
import { AuthState } from '../../shared/types/types';
import { firestore } from '../../utils/firebase';
import { mapAuthStateToDatabaseUser, mapDatabaseDataToUser } from '../../utils/user/UserDataUtils';
import UserAPI from './UserAPI';
import { CREATE_EVENT, DATABASE_USER_DOCUMENT } from '../../utils/analytics/enums';
import { logDatabaseEvent } from '../../utils/analytics/eventLogger';
import { toastDanger } from '../../utils/notifications';
import UserSmart from '../../shared/classes/UserSmart';
import { COLLECTIONS } from '../FirebaseConstants';

class UserGeneralAPI {
  static listenToMyData = (
    authState: AuthState, setUserData: Dispatch<SetStateAction<UserSmart>>,
  ) => {
    if (authState.userState !== 'loggedIn') return () => { };
    if (authState.userId.length === 0) return () => { };
    // return firestore()
    //   .collection('users')
    //   .doc(authState.userId)
    //   .onSnapshot(async (snapshot: any) => {
    const userDocRef = doc(firestore, COLLECTIONS.USERS, authState.userId);
    return onSnapshot(userDocRef, async (snapshot) => {
      if (!snapshot.exists()) {
        UserAPI.General.dbCreateUserData(authState);
        return;
      }
      const user = mapDatabaseDataToUser(snapshot.data(), authState.userId);
      const userSmart = await UserSmart.create(user);

      UserAPI.Data.dbUpdatePhotoUrlIfNotUpdated(user, authState);
      UserAPI.Data.dbUpdateNameIfNotUpdated(user, authState);
      UserAPI.Meta.dbUpdatePrivateIdIfNotUpdated(user, authState);

      UserAPI.Billing.createStripeCustomerIdIfNotCreated(user);

      setUserData(userSmart);

      const user2 = mapDatabaseDataToUser(snapshot.data(), authState.userId);
      const userWithFriendListv2 = await dbGetUserWithFriendList(user2, snapshot.data());
      const userSmart2 = await UserSmart.create(userWithFriendListv2);
      setUserData(userSmart2);
    }, (error) => {
      console.error(`Error in UserGeneralAPI.listenToMyData ${error.message}`, { error, authState });
      captureException(error, { extra: { authState, functionName: 'UserGeneralAPI.listenToMyData' } });
    });
  };

  /**
   * We've had issues with the User data being set even though the user has already
   * data. So we've made this to a transaction where we first check if the document exist.
   */
  static dbCreateUserData = async (authState: AuthState) => {
    const databaseUser = mapAuthStateToDatabaseUser(authState);

    // const userDocRef = firestore().collection('users').doc(authState.userId);
    const userDocRef = doc(firestore, COLLECTIONS.USERS, authState.userId);

    // return firestore()
    //   .runTransaction((transaction) => transaction.get(userDocRef).then((userDoc) => {
    return runTransaction(firestore, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (userDoc.exists()) return;
      transaction.set(userDocRef, databaseUser);
    })
      .then(() => {
        console.log('Set user data for first time', databaseUser);
        logDatabaseEvent(authState.userId, CREATE_EVENT, DATABASE_USER_DOCUMENT);
      })
      .catch((error) => {
        console.error(`Error in UserGeneralAPI.dbCreateUserData ${error.message}`, { error, authState });
        captureException(error, { extra: { authState, functionName: 'UserGeneralAPI.dbCreateUserData' } });
        toastDanger('Error', 'Failed to create user data. Please try again');
      });
  };
}

export default UserGeneralAPI;
